<template>
  <div>
    <v-row
        v-if="!embedded"
        style="background-color: #ECEFF1;">
      <v-col>
        <v-row>
          <v-spacer></v-spacer>
          <v-col>
            <v-img height="150" contain src="@/assets/agendease-logo-name-below-transparent.png"></v-img>
          </v-col>
          <v-spacer></v-spacer>
        </v-row>
      </v-col>
    </v-row>
    <v-row>
      <v-spacer></v-spacer>
      <v-col>
        <h2>Find an organization</h2>
        <p>Receive notifications from any organization that is using Agendease. Just choose the organization you are interested in from the list below. You will also be able to select among the boards of the organization.</p>
        <p>Begin displaying the list by entering search text in the field below. You can further filter the list by entering the {{this.subdivisionLabel.toLowerCase()}} in which it is located.</p>
      </v-col>
      <v-spacer></v-spacer>
    </v-row>
    <v-row>
      <v-spacer></v-spacer>
      <v-col>
        <v-text-field
        label="Organization Name"
        outlined
        clearable
        v-model="enteredSearchText"
        @input="searchTextChanged"></v-text-field>
      </v-col>
      <v-spacer></v-spacer>
    </v-row>
    <v-row>
      <v-spacer></v-spacer>
      <v-col>
        <v-select
        label="Country"
        :items="countries"
        v-model="selectedCountry"
        clearable
        v-on:change="countryChanged"></v-select>
      </v-col>
      <v-col>
        <v-select
        :label="subdivisionLabel"
        :items="subdivisions"
        :disabled="subdivisionDisabled"
        v-model="selectedSubdivision"
        clearable
        @change="subdivisionChanged"></v-select>
      </v-col>
      <v-spacer></v-spacer>
    </v-row>
    <div
      v-if="organizationResults == ''">
    <v-row>
      <v-spacer></v-spacer>
      <v-col>
      <v-alert
        text
        outlined
        color="info"
        icon="mdi-account-group-outline"
      >
        {{standardErrorMessage}}
      </v-alert>
      </v-col>
      <v-spacer></v-spacer>
    </v-row>
    <v-row
      v-if="displayAddMyOrganizationButton">
      <v-spacer></v-spacer>
      <v-col
      style="text-align:center;">
          <v-btn
          color=info
          class="elevation-10"
          large>I would like for my local government to use Agendease</v-btn>
      </v-col>
      <v-spacer></v-spacer>
    </v-row>
    </div>
    <div
        v-else>
      <v-row
        v-if="atMaxListSize">
        <v-spacer></v-spacer>
        <v-col>
          <v-alert
          text
          outlined
          color="primary"
          icon="mdi-text-search"
        >
          Showing the first ten results of your search. Refine your search to show more results.
          </v-alert>
       </v-col>
        <v-spacer></v-spacer>
      </v-row>
      <v-row>
        <v-spacer></v-spacer>
        <v-col>
          <v-list
          v-for="result in organizationResults"
          v-bind:key="result.id"
          style="background-color: transparent;"
          light>
            <v-list-item
            class="my-1"
            style="border: solid 2px black;background-color: white;border-radius: 10px;"
            v-if="notAlreadyAMember(result.id)"
            >
                <v-list-item-avatar>
                  <v-img
                    :src="pathToClientAvatars + result.logo"
                  >
                  </v-img>
                </v-list-item-avatar>
                <v-list-item-content>
                <v-list-item-title>
                  {{result.name}}
                </v-list-item-title>
                <v-list-item-subtitle>
                  {{result.city}}, {{result.state}}
                </v-list-item-subtitle>
              </v-list-item-content>
              <v-list-item-action>
                <v-tooltip top>
                  <template v-slot:activator="{ on }">
                  <v-btn icon
                    v-on="on"
                    @click="requestToJoin(result)">
                    <v-icon color="secondary lighten-1" x-large>mdi-email-plus-outline</v-icon>
                  </v-btn>
                  </template>
                  <span>
                    Receive notifications from {{result.name}}
                  </span>
                </v-tooltip>
              </v-list-item-action>
            </v-list-item>
            <v-list-item
              v-else>
              <v-list-item-icon>
                <v-icon
                color=positive
                large>mdi-check-outline</v-icon>
              </v-list-item-icon>
              <v-list-item-content>
              <v-list-item-title>
                You are already signed up to receive notifications from at least one board for {{result.name}}.
              </v-list-item-title>
              <v-list-item-subtitle>
                {{result.city}}, {{result.state}}
              </v-list-item-subtitle>
              </v-list-item-content>
              <v-list-item-action>
                <v-tooltip top>
                  <template v-slot:activator="{ on }">
                  <v-btn icon
                    v-on="on"
                    @click="requestToJoin(result)">
                    <v-icon color="primary lighten-1" x-large>mdi-cogs</v-icon>
                  </v-btn>
                  </template>
                  <span>
                    Manage your notification settings for {{result.name}}
                  </span>
                </v-tooltip>
              </v-list-item-action>
            </v-list-item>
          </v-list>
        </v-col>
        <v-spacer></v-spacer>
      </v-row>
    </div>

    <notification-settings v-model="modals.notificationSettings" :client="clientToJoin" :userOrganizations="userOrganizations" @reload="getAllOrganizations"></notification-settings>
  </div>
</template>

<script>
import Vue from 'vue'
import localities from '@/assets/general-settings/localities.json'
import axios from 'axios'
import NotificationSettings from '@/components/LandingPages/NotificationSettings.vue'
import NotificationPublicSignups from '@/models/NotificationPublicSignups'

export default Vue.extend({
  name: 'NotificationSignup',
  components: {
    NotificationSettings
  },
  props: {
    embedded: Boolean
  },
  data: () => ({
    modals: {
      notificationSettings: false
    },
    subdivisionLabel: 'State',
    countries: localities.countries,
    subdivisions: [],
    subdivisionDisabled: true,
    selectedCountry: null,
    selectedSubdivision: null,
    enteredSearchText: null,
    activeOrganizations: {},
    organizationResults: [],
    listMaxSize: 10,
    atMaxListSize: false,
    pathToClientAvatars: process.env.VUE_APP_IMAGES_S3_BUCKET + '/' + process.env.VUE_APP_IMAGES_S3_FOLDER_ORGANIZATION_AVATARS + '/',
    messageType: 'standard',
    displayAddMyOrganizationButton: false,
    userOrganizationsData: [],
    clientToJoin: {
      name: ''
    }
  }),
  computed: {
    collectionToSearch () {
      if ((this.enteredSearchText === null || this.enteredSearchText === '') && this.selectedCountry === null && this.selectedSubdivision === null) {
        return []
      }

      if (this.selectedCountry !== null) {
        if (!this.activeOrganizations.bySubdivision[this.selectedCountry]) {
          return []
        }

        if (this.selectedSubdivision !== null) {
          if (!this.activeOrganizations.bySubdivision[this.selectedCountry].subdivisions[this.selectedSubdivision]) {
            return []
          }

          return this.activeOrganizations.bySubdivision[this.selectedCountry].subdivisions[this.selectedSubdivision]
        } else {
          return this.activeOrganizations.bySubdivision[this.selectedCountry].clients
        }
      } else {
        return this.activeOrganizations.all
      }
    },
    standardErrorMessage () {
      switch (this.messageType) {
        case 'noResults':
          return 'There are no results for the search text that you entered.'
        case 'noCountry':
          return 'There are no organizations using Agendease in this country yet.'
        case 'noSubdivision':
          return 'There are no organizations using Agendease in this ' + this.subdivisionLabel.toLowerCase() + ' yet.'
        case 'standard':
        default:
          return 'Enter search text or choose a ' + this.subdivisionLabel.toLowerCase() + ' to display a list of organizations to choose from.'
      }
    },
    userOrganizations () {
      const organizationIds = {}
      this.userOrganizationsData.forEach(element => {
        if (!organizationIds[element.board.client_id]) {
          organizationIds[element.board.client_id] = { boards: [] }
        }
        organizationIds[element.board.client_id].boards.push(element.board.id)
      })

      return organizationIds
    }
  },
  watch: {
    collectionToSearch () {
      this.displayAddMyOrganizationButton = false
      if ((this.enteredSearchText === null || this.enteredSearchText === '') && this.selectedCountry === null && this.selectedSubdivision === null) {
        this.messageType = 'standard'
      } else if (this.enteredSearchText !== '') {
        this.displayAddMyOrganizationButton = true
        this.messageType = 'noResults'
      }

      if (this.selectedCountry !== null) {
        if (!this.activeOrganizations.bySubdivision[this.selectedCountry]) {
          this.displayAddMyOrganizationButton = true
          this.messageType = 'noCountry'
          return
        }
      }

      if (this.selectedSubdivision !== null) {
        if (!this.activeOrganizations.bySubdivision[this.selectedCountry].subdivisions[this.selectedSubdivision]) {
          this.displayAddMyOrganizationButton = true
          this.messageType = 'noSubdivision'
          return
        }
      }

      this.messageType = 'noResults'
    }
  },
  methods: {
    getAllOrganizations () {
      axios.get(process.env.VUE_APP_BACKEND_URL + '/api/clients/active-organizations')
        .then((response) => {
          this.activeOrganizations = response.data
          this.getUserNotificationSignups()
        }).catch(function (error) {
          console.log(error)
        })
    },
    getUserNotificationSignups () {
      NotificationPublicSignups
        .custom('notification-public-signups/user')
        .params({
          board: true
        })
        .select({
          notification_public_signups: ['board_id', 'status']
        })
        .get()
        .then(response => {
          this.userOrganizationsData = response
        }).catch(function (error) {
          console.log(error)
        })
    },
    searchList () {
      const searchTextRegEx = (this.enteredSearchText ? this.enteredSearchText.toLowerCase() : '')
      this.atMaxListSize = false

      this.organizationResults = []
      let listSize = 0
      if (this.collectionToSearch) {
        this.collectionToSearch.forEach(element => {
          if (listSize === this.listMaxSize) {
            this.atMaxListSize = true
            return false
          }

          if (element.name.toLowerCase().search(searchTextRegEx) !== -1) {
            this.organizationResults.push(element)
            listSize++
          } else {
          }
        })
      }
    },
    searchTextChanged () {
      this.searchList()
    },
    subdivisionChanged () {
      this.searchList()
    },
    countryChanged () {
      if (this.selectedCountry === null) {
        this.selectedSubdivision = null
        this.subdivisionDisabled = true
      } else {
        this.subdivisionLabel = localities.subdivisions[this.selectedCountry].subdivisionName
        this.subdivisions = localities.subdivisions[this.selectedCountry].subdivisions
        this.subdivisionDisabled = false
      }

      this.searchList()
    },
    requestToJoin (clientToJoin) {
      this.clientToJoin = clientToJoin
      this.modals.notificationSettings = true
    },
    notAlreadyAMember (clientId) {
      return !(clientId in this.userOrganizations)
    }
  },
  beforeMount () {
    this.getAllOrganizations()
  }
})
</script>
