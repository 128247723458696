<template>

  <v-dialog
    width="900"
    v-model="show"
    :persistent="this.$store.state.modalNotCloseOutside"
    :no-click-animation="this.$store.state.modalWithoutAnimation"
  >
    <v-card elevation="0" rounded="0" class="pa-8 veryDarkGrey--text">

      <v-card-title class="d-flex justify-center align-start headline flex-column mb-6">
        <span>Receive notifications from <span class="font-weight-black">{{ client.name }}</span></span>
      </v-card-title>

      <v-card-text v-if="!hasBeenSent">
        <v-row>
            <v-col cols="12">
                <v-row>
                  <v-col cols=12>
                    <p>Choose the boards below for which you want to receive an email notification when there is a meeting for the board.</p>
                    <p>The notifications are sent out at the time specified by the organization. The organization may choose not to send notifications in some cases. If you have questions about why you have not received a notification for a board you are signed up to receive, please contact the organization.</p>
                    <p>After you sign up to receive notifications for a board, you can always unsubscribe from receiving the board's notifications.</p>
                  </v-col>
                </v-row>
                <v-row
                  v-if="clientBoards.length > 0">
                  <v-col>
                    <v-row
                      v-for="clientBoard in clientBoards"
                      v-bind:key="clientBoard.id">
                      <v-col>
                        <v-checkbox
                          v-model="selectedBoards"
                          :value="clientBoard.id">
                          <template v-slot:label>
                            <div>
                              {{clientBoard.name}}
                            </div>
                          </template>
                        </v-checkbox>
                      </v-col>
                    </v-row>
                    <v-row>
                      <v-col cols=12
                      style="text-align:center;">
                        <v-btn
                        color="primary"
                        @click="saveSettings"
                        >
                      <v-progress-circular
                        v-if="saving===true"
                        indeterminate
                        size="16"
                        class="mr-3"
                        ></v-progress-circular>
                      {{ buttonLabel}}</v-btn>
                      </v-col>
                    </v-row>
                  </v-col>
                </v-row>
                <v-row
                 v-else>
                  <v-alert
                    text
                    outlined
                    color="info"
                  >
                    {{client.name}} does not have any board for which you can sign up to receive notifications.
                  </v-alert>
                </v-row>
            </v-col>
        </v-row>
      </v-card-text>
      <v-card-text v-else>
        <v-row
          v-if="requestSuccess">
            <v-col cols="12">
                <v-row>
                  <v-col cols=12>
                    <p>Your notification settings have been saved for {{client.name}} boards.</p>
                  </v-col>
                </v-row>
                <v-row>
                </v-row>
            </v-col>
            <v-col cols="12">
                <v-row>
                  <v-col
                    cols=12
                    style="text-align:center">
                    <v-icon
                      color="accentGreen"
                      size="100">
                      mdi-email-check-outline</v-icon>
                  </v-col>
                </v-row>
            </v-col>
        </v-row>
      </v-card-text>

      <v-card-actions class="d-flex align-center justify-center">
        <v-spacer></v-spacer>
        <v-btn depressed width="130" @click="show=false" class="mr-2">Close</v-btn>
      </v-card-actions>

    </v-card>
  </v-dialog>

</template>

<script>
import Vue from 'vue'
import axios from 'axios'
import NotificationPublicSignups from '@/models/NotificationPublicSignups'

export default Vue.extend({
  props: {
    value: {
      required: true,
      type: Boolean
    },
    client: {},
    userOrganizations: {}
  },
  data () {
    return {
      saving: false,
      buttonLabel: 'Save Notification Settings',
      hasBeenSent: false,
      requestSuccess: true,
      clientBoards: {},
      selectedBoards: []
    }
  },
  computed: {
    show: {
      get () {
        return this.value
      },
      set (value) {
        this.$emit('input', value)
      }
    }
  },
  methods: {
    setSelectedBoards () {
      const userSelectedBoards = []
      if (this.client.id in this.userOrganizations) {
        this.userOrganizations[this.client.id].boards.forEach(element => {
          userSelectedBoards.push(element)
        })
      }
      this.selectedBoards = userSelectedBoards
    },
    saveSettings () {
      this.saving = true
      this.buttonLabel = 'Saving Settings'
      const boardSelections = { selectedBoards: this.selectedBoards, allBoards: this.clientBoards }

      axios.post(process.env.VUE_APP_BACKEND_URL + '/api/notification-public-signups/', boardSelections)
        .then((response) => {
          this.saving = false
          this.hasBeenSent = true
        })
    },
    getClientBoards () {
      NotificationPublicSignups
        .params({
          functionName: 'clientBoards',
          columns: ['name', 'id'],
          client_id: this.client.id
        })
        .get()
        .then(response => {
          this.clientBoards = response
        }).catch(function (error) {
          console.log(error)
        })
    }
  },
  watch: {
    show (value) {
      if (value === true) {
        this.setSelectedBoards()
        this.getClientBoards()
      } else {
        this.hasBeenSent = false
        this.$emit('reload', true)
      }
    },
    hasBeenSent (value) {
      if (!value) {
        this.buttonLabel = 'Save Notification Settings'
        this.requestSuccess = true
        this.requestMessage = ''
      }
    }
  }
})
</script>
